import React from "react";

const Message = ({ viewBox = "0 0 24  24", size = 24, ...props }) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2744"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M761.984 1009.28H253.696C138.368 1009.28 44.8 915.712 44.8 800.384V292.096C44.8 176.768 138.368 83.2 253.696 83.2h508.288c115.328 0 208.896 93.568 208.896 208.896v508.288c0 115.328-93.568 208.896-208.896 208.896z"
      fill="#00E560"
      p-id="2745"
    ></path>
    <path
      d="M507.776 265.856c-72.576 0-139.776 20.352-193.536 54.528-54.272 34.432-94.592 83.2-113.408 139.264-7.552 22.528-11.648 46.464-11.648 71.04 0 92.16 56.448 172.928 142.208 220.544 7.68 4.224 11.648 13.44 8.32 21.632-6.4 15.616-14.592 30.592-24.32 44.672-3.072 4.352 0.768 10.368 6.016 8.96 29.44-7.808 56.96-19.072 81.92-33.28 9.088-5.248 19.456-6.912 29.696-4.864 23.936 4.736 49.024 7.296 74.88 7.296 176 0 318.592-118.656 318.592-264.832 0-146.304-142.72-264.96-318.72-264.96z"
      fill="#FFFFFF"
      p-id="2746"
    ></path>
  </svg>
);

export default Message;
