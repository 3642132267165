import React from "react";

const CommentIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#696cff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="3607"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M312.6 949.5c-7.9 0-15.4-3.1-21.1-8.6-5.7-5.6-8.9-13.2-9-21.2-0.1-8 3-15.6 8.6-21.4l198.1-201.9c5.6-5.7 13.5-9 21.5-9h315.7c16.2 0 29.4-13.2 29.4-29.4V198.1c0-16.2-13.2-29.4-29.4-29.4H197.6c-16.2 0-29.4 13.2-29.4 29.4v459.8c0 16.2 13.2 29.4 29.4 29.4h105.1c16.6 0 30.1 13.5 30.1 30.1s-13.5 30.1-30.1 30.1H197.6c-49.4 0-89.6-40.2-89.6-89.6V198.1c0-49.4 40.2-89.6 89.6-89.6h628.8c49.4 0 89.6 40.2 89.6 89.6v459.8c0 49.4-40.2 89.6-89.6 89.6H523.3l-189.2 193c-5.7 5.8-13.4 9-21.5 9z"
      fill={color}
      p-id="3608"
    ></path>
    <path
      d="M262 466c-19.3 0-35-15.7-35-35s15.7-35 35-35h70c19.3 0 35 15.7 35 35s-15.7 35-35 35h-70zM477 466c-19.3 0-35-15.7-35-35s15.7-35 35-35h70c19.3 0 35 15.7 35 35s-15.7 35-35 35h-70zM692 466c-19.3 0-35-15.7-35-35s15.7-35 35-35h70c19.3 0 35 15.7 35 35s-15.7 35-35 35h-70z"
      fill={color}
      p-id="3609"
    ></path>
  </svg>
);

export default CommentIcon;
