import React, { Component } from "react";
import "../style/customer.scss";
import HeadBar from "./common/HeadBar";
import ReactFlagsSelect from "react-flags-select";
import ReactPhoneInput from "react-telephone-input/lib/withStyles";
import flagImg from "../image/flag.png";
import * as FormValidation from "../utils/FormValidation";
import Checkbox from "@material-ui/core/Checkbox";
import * as FacebookAPI from "../apis/FacebookAPI";
import * as UpdateOrderAPI from "../apis/UpdateOrderAPI";
import Loading from "./common/Loading";
import SearchIcon from "../icon/SearchIcon";
import AllowRight from "../icon/AllowRight";
import UrlIcon from "../icon/UrlIcon";
import * as GetAddressAPI from "../apis/GetAddressAPI";
// import { Select } from "@material-ui/core";
import Select from "react-select";
import SolidAllowUp from "../icon/SolidAllowUp";
import SolidAllowDown from "../icon/SolidAllowDown";
import ShoppingCart from "../icon/ShoppingCart";
import ShippingCart from "../icon/ShippingCart";
import Approve from "../icon/Approve";
import AllowLeft from "../icon/AllowLeft";
import AllowsLeft from "../icon/AllowsLeft";

import { BASE_URL } from "../constants/ApiEndpointConstants";
import { Radio } from "antd";
import Reload from "./common/Reload";
import * as SubmitPayAPI from "../apis/SubmitPayAPI";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "./common/Popup";
import Cookie from "react-cookies";
import * as FackbookAPI from "../apis/FacebookAPI";
import * as GetPixelAPI from "../apis/GetPixelAPI";
import * as CountPixelEventAPI from "../apis/CountPixelEventAPI";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      country: "JP",
      firstName: "",
      lastName: "",
      email1: "",
      email2: "",
      phone1: "",
      phone2: "",
      address: "",
      province: null,
      state: "",
      SSN: "",
      zip: "",
      isEmail1: false,
      isEmail2: false,
      isPhone1: false,
      isPhone2: false,
      isFirstName: false,
      isLastName: false,
      isAddress: false,
      isProvince: false,
      isCountry: false,
      isZip: false,
      isSSN: false,
      tip: "",
      loadingShow: false,
      riBen: [
        {
          value: "北海道",
          label: "北海道",
        },
        {
          value: "青森県",
          label: "青森県",
        },
        {
          value: "岩手県",
          label: "岩手県",
        },
        {
          value: "宮城県",
          label: "宮城県",
        },
        {
          value: "秋田県",
          label: "秋田県",
        },
        {
          value: "山形県",
          label: "山形県",
        },
        {
          value: "福島県",
          label: "福島県",
        },
        {
          value: "茨城県",
          label: "茨城県",
        },
        {
          value: "栃木県",
          label: "栃木県",
        },
        {
          value: "群馬県",
          label: "群馬県",
        },
        {
          value: "埼玉県",
          label: "埼玉県",
        },
        {
          value: "千葉県",
          label: "千葉県",
        },
        {
          value: "東京都",
          label: "東京都",
        },
        {
          value: "神奈川県",
          label: "神奈川県",
        },
        {
          value: "新潟県",
          label: "新潟県",
        },
        {
          value: "富山県",
          label: "富山県",
        },
        {
          value: "石川県",
          label: "石川県",
        },
        {
          value: "福井県",
          label: "福井県",
        },
        {
          value: "山梨県",
          label: "山梨県",
        },
        {
          value: "長野県",
          label: "長野県",
        },
        {
          value: "岐阜県",
          label: "岐阜県",
        },
        {
          value: "静岡県",
          label: "静岡県",
        },
        {
          value: "愛知県",
          label: "愛知県",
        },
        {
          value: "三重県",
          label: "三重県",
        },
        {
          value: "滋賀県",
          label: "滋賀県",
        },
        {
          value: "京都府",
          label: "京都府",
        },
        {
          value: "大阪府",
          label: "大阪府",
        },
        {
          value: "兵庫県",
          label: "兵庫県",
        },
        {
          value: "奈良県",
          label: "奈良県",
        },
        {
          value: "和歌山県",
          label: "和歌山県",
        },
        {
          value: "鳥取県",
          label: "鳥取県",
        },
        {
          value: "島根県",
          label: "島根県",
        },
        {
          value: "岡山県",
          label: "岡山県",
        },
        {
          value: "広島県",
          label: "広島県",
        },
        {
          value: "山口県",
          label: "山口県",
        },
        {
          value: "徳島県",
          label: "徳島県",
        },
        {
          value: "香川県",
          label: "香川県",
        },
        {
          value: "愛媛県",
          label: "愛媛県",
        },
        {
          value: "高知県",
          label: "高知県",
        },
        {
          value: "福岡県",
          label: "福岡県",
        },
        {
          value: "佐賀県",
          label: "佐賀県",
        },
        {
          value: "長崎県",
          label: "長崎県",
        },
        {
          value: "熊本県",
          label: "熊本県",
        },
        {
          value: "大分県",
          label: "大分県",
        },
        {
          value: "宮崎県",
          label: "宮崎県",
        },
        {
          value: "鹿児島県",
          label: "鹿児島県",
        },
        {
          value: "沖縄県",
          label: "沖縄県",
        },
      ],
      showCart: false,
      num: 0,
      total: 0,
      vehicleModel: "",
      vehicleModel2: "",
      vehicleModel3: "",
      reloadingShow: false,
      contactType: "T shirt",
      test_drive_package: 0,
      payment_intent_id: null,
      clientSecret: "",
      stripePromise: "",
      userDetails: {},
      count: JSON.parse(localStorage.getItem("count")),
      url: "",
      amount: "",
      title: "",
      img: "",
    };
    this.inputChange = this.inputChange.bind(this);
    this.blurInput = this.blurInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.clickToCheck = this.clickToCheck.bind(this);
    this.routeDocument = this.routeDocument.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.showCart = this.showCart.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("orderDetail")) {
      let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
      this.setState({
        num: orderDetail.num,
        total: orderDetail.amount,
        vehicleModel: orderDetail.vehicleModel,
        vehicleModel2: orderDetail.vehicleModel2,
        vehicleModel3: orderDetail.vehicleModel3,
        title: orderDetail.title,
        img: orderDetail.img,
      });
    }
  }

  inputChange(e, type) {
    let telephone;
    switch (type) {
      case "email1":
        this.setState({
          email1: e.target.value,
          email2: e.target.value,
          tip: "",
        });
        break;
      case "email2":
        this.setState({ email2: e.target.value.trim(), tip: "" });
        break;
      case "firstName":
        this.setState({ firstName: e.target.value.trim(), tip: "" });
        break;
      case "lastName":
        this.setState({ lastName: e.target.value.trim(), tip: "" });
        break;
      case "country":
        this.setState({ country: e.target.value.trim(), tip: "" });
        break;
      case "address":
        this.setState({ address: e.target.value.trim(), tip: "" });
        break;
      case "zip":
        this.setState({ zip: e.target.value.trim(), tip: "" });
        break;
      case "province":
        this.setState({ province: e, tip: "" });
        break;
      case "SSN":
        this.setState({ SSN: e.target.value.trim(), tip: "" });
        break;
      case "phone1":
        telephone = e.replace("-", "").replace(" ", "");
        this.setState({ phone1: telephone, phone2: telephone, tip: "" });
        break;
      case "phone2":
        telephone = e.replace("-", "").replace(" ", "");
        this.setState({ phone2: telephone, tip: "" });
        break;
      default:
        break;
    }
  }

  blurInput(e, type) {
    let reg;
    switch (type) {
      case "email1":
        if (!FormValidation.isValidEmail(e.target.value)) {
          this.setState({ isEmail1: true, isEmail2: true });
        } else {
          this.setState({ isEmail1: false, isEmail2: false });
        }
        break;
      case "email2":
        if (!FormValidation.isValidEmail(e.target.value)) {
          this.setState({ isEmail2: true });
        } else {
          this.setState({ isEmail2: false });
        }
        break;
      case "firstName":
        if (e.target.value.trim() === "") {
          this.setState({ isFirstName: true });
        } else {
          this.setState({ isFirstName: false });
        }
        break;
      case "lastName":
        if (e.target.value.trim() === "") {
          this.setState({ isLastName: true });
        } else {
          this.setState({ isLastName: false });
        }
        break;
      case "country":
        if (e.target.value.trim() === "") {
          this.setState({ isCountry: true });
        } else {
          this.setState({ isCountry: false });
        }
        break;
      case "city":
        if (e.target.value.trim() === "") {
          this.setState({ isCity: true });
        } else {
          this.setState({ isCity: false });
        }
        break;
      case "address":
        if (e.target.value.trim() === "") {
          this.setState({ isAddress: true });
        } else {
          this.setState({ isAddress: false });
        }
        break;
      case "province":
        if (e.value.trim() === "") {
          this.setState({ isProvince: true });
        } else {
          this.setState({ isProvince: false });
        }
        break;
      case "SSN":
        reg = /^[0-9]{9}$/;
        if (!reg.test(e.target.value)) {
          this.setState({ isSSN: true });
        } else {
          this.setState({ isSSN: false });
        }
        break;
      case "zip":
        reg = /^[a-zA-Z0-9-_]{4,30}$/;
        if (!reg.test(e.target.value)) {
          this.setState({ isZip: true });
        } else {
          this.setState({ isZip: false });
        }
        break;
      case "phone1":
        if (FormValidation.isValidPhoneNumber(e)) {
          this.setState({ isPhone1: false, isPhone2: false });
        } else {
          this.setState({ isPhone1: true, isPhone2: true });
        }
        break;
      case "phone2":
        if (FormValidation.isValidPhoneNumber(e)) {
          this.setState({ isPhone2: false });
        } else {
          this.setState({ isPhone2: true });
        }
        break;
      default:
        break;
    }
  }

  handleChange(e) {
    this.setState({ checked: e.target.checked });
  }

  setSelected(code) {
    this.setState({ country: code });
    console.log(code);
  }

  clickToCheck() {
    const {
      isAddress,
      isCountry,
      isEmail1,
      isEmail2,
      isFirstName,
      isLastName,
      isPhone1,
      isPhone2,
      isProvince,
      isZip,
      isSSN,
      firstName,
      lastName,
      email1,
      email2,
      province,
      address,
      zip,
      phone1,
      phone2,
      SSN,
      country,
    } = this.state;

    let checkFlag =
      isAddress ||
      isCountry ||
      isEmail1 ||
      isEmail2 ||
      isFirstName ||
      isLastName ||
      isPhone1 ||
      isPhone2 ||
      isProvince ||
      isZip ||
      (country === "US" && isSSN);
    console.log(checkFlag);
    console.log(isAddress);
    console.log(isCountry);
    console.log(isEmail1);
    console.log(isEmail2);
    console.log(isPhone1);
    console.log(isPhone2);
    console.log(isSSN);
    if (checkFlag) {
      if (country === "US") {
        if (isSSN) {
          this.setState({ tip: "Invalid social security number" });
          return;
        }
      }
      if (isEmail1) {
        this.setState({ tip: "Invalid email address" });
        return;
      }
      if (isEmail2) {
        this.setState({ tip: "Invalid driver email address" });
        return;
      }
      if (isPhone1) {
        this.setState({ tip: "Invalid phone number" });
        return;
      }
      if (isPhone2) {
        this.setState({ tip: "Invalid driver phone number" });
        return;
      }
    } else {
      this.setState({ tip: "" });
      let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
      console.log(address);
      orderDetail.address = address;
      orderDetail.country = country;
      orderDetail.driveEmail = email2;
      orderDetail.drivePhone = phone2;
      orderDetail.email = email1;
      orderDetail.phone = phone1;
      orderDetail.firstName = firstName;
      orderDetail.lastName = lastName;
      orderDetail.snCode = SSN;
      orderDetail.state = province.value;
      orderDetail.zipCode = zip;

      this.toggleShow();
      let ad = "";
      if (localStorage.getItem("ad")) {
        ad = JSON.parse(localStorage.getItem("ad")) || "";
        console.log(ad);
      }
      orderDetail.ad = ad;
      orderDetail.id = "";
      UpdateOrderAPI.updateOrderInformation(orderDetail).then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          console.log(data);
          orderDetail.id = data.id;
          console.log(orderDetail.id);
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        }
      });
      this.routeDocument();
    }
  }

  routeDocument() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Document",
        search: location.search,
      });
  }

  clickToCheck() {
    let tgid = JSON.parse(localStorage.getItem("tgid"));
    let fbqToken = JSON.parse(localStorage.getItem("fbqToken"));
    let pixel = JSON.parse(localStorage.getItem("pixel"));
    let amount = JSON.parse(localStorage.getItem("amount"));
    let amount_fast = JSON.parse(localStorage.getItem("amount_fast"));
    const {
      isAddress,
      isCountry,
      isEmail1,
      isEmail2,
      isFirstName,
      isLastName,
      isPhone1,
      isPhone2,
      isProvince,
      isZip,
      isSSN,
      isCity,
      firstName,
      lastName,
      email1,
      email2,
      province,
      address,
      zip,
      phone1,
      phone2,
      SSN,
      country,
      city,
      num,
      total,
      test_drive_package,
      c,
    } = this.state;

    let checkFlag =
      isAddress ||
      isCountry ||
      isEmail1 ||
      isEmail2 ||
      isFirstName ||
      isLastName ||
      isPhone1 ||
      isPhone2 ||
      isProvince ||
      isZip ||
      isCity ||
      (country === "US" && isSSN);
    if (checkFlag) {
      if (country === "US") {
        if (isSSN) {
          this.setState({ tip: "Invalid social security number" });
          return;
        }
      }
      if (isEmail1) {
        this.setState({ tip: "Invalid email address" });
        return;
      }
      if (isEmail2) {
        this.setState({ tip: "Invalid driver email address" });
        return;
      }
      if (isPhone1) {
        this.setState({ tip: "Invalid phone number" });
        return;
      }
      if (isPhone2) {
        this.setState({ tip: "Invalid driver phone number" });
        return;
      }
    } else {
      this.setState({ tip: "" });
      let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
      console.log(address);
      orderDetail.address = address;
      orderDetail.country = country;
      orderDetail.driveEmail = email2;
      orderDetail.drivePhone = phone2;
      orderDetail.email = email1;
      orderDetail.phone = phone1;
      orderDetail.firstName = firstName;
      orderDetail.lastName = lastName;
      orderDetail.snCode = SSN;
      orderDetail.city = city;
      orderDetail.state = province;
      orderDetail.zipCode = zip;
      let ad = "";
      if (localStorage.getItem("ad")) {
        ad = JSON.parse(localStorage.getItem("ad")) || "cutting";
      }
      orderDetail.ad = ad;
      orderDetail.id = "";
      orderDetail.orderNu = "";
      orderDetail.tgid = tgid;
      orderDetail.fbqToken = fbqToken;
      orderDetail.pixel = pixel;
      orderDetail.amount = amount;
      orderDetail.amount_fast = amount_fast;
      orderDetail.curcrency = 1;
      localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
      // UpdateOrderAPI.updateOrderInformation(orderDetail)
      //   .then((res) => {
      //     this.toggleShow();
      //     var data = res.data.data;
      //     if (data) {
      //       console.log(data);
      //       orderDetail.id = data.id;
      //       console.log(orderDetail.id);
      //       localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
      //       console.log(data);
      //       // this.clickToPay();
      //       // offer
      //       this.clickToOfferPay();
      //     }
      //   })
      //   .catch((err) => {});
      this.clickToOfferPay();

      // fbq;
      if (window.fbq) {
        let event_id = Math.round(Math.random() * 10000000000);
        window.fbq(
          "track",
          "AddPaymentInfo",
          {
            content_name: orderDetail.vehicleModel,
            content_ids: [orderDetail.vehicleModel],
            currency: "EUR",
            value: orderDetail.amount,
            contents: [
              {
                id: orderDetail.vehicleModel,
                title: orderDetail.vehicleModel,
                quantity: 1,
              },
            ],
          },
          { eventID: event_id }
        );
        let customdata = {
          content_name: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "EUR",
          value: orderDetail.amount,
          contents: [
            {
              id: orderDetail.vehicleModel,
              title: orderDetail.vehicleModel,
              quantity: 1,
            },
          ],
        };
        let userdata = {
          em: email1,
          ph: phone1,
          fn: firstName,
          ln: lastName,
          ct: city,
          st: province,
          zp: zip,
          country: country,
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        FackbookAPI.Facebook(
          event_id,
          "AddPaymentInfo",
          customdata,
          userdata,
          fbqToken,
          pixel
        ).then((res) => {
          console.log(res);
        });
        // fb
        let url = BASE_URL.replace("https://", "");
        CountPixelEventAPI.countPixelEvent(url, ad, "AddPaymentInfo").then(
          (res) => {
            console.log(res);
          }
        );
      }
    }
  }

  clickToOfferPay() {
    let fbqToken = JSON.parse(localStorage.getItem("fbqToken"));
    let pixel = JSON.parse(localStorage.getItem("pixel"));
    let { count, title, img } = this.state;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    // localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    console.log(count);
    if (Cookie.load("prohibitd_purchase")) {
      return window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "Les achats d'aujourd'hui ont été épuisés!",
        })
      );
    }
    if (count === 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "Les achats d'aujourd'hui ont été épuisés!",
        })
      );
      // 24小时内只允许点三次
      let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      let value = "Prohibitd purchase";
      Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
      return;
    }
    count--;
    console.log(count);
    localStorage.setItem("count", JSON.stringify(count));
    this.setState({ count });
    let ad = "";
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "cutting";
      console.log(ad);
    }
    orderDetail.ad = ad;
    this.toggleReload();
    SubmitPayAPI.submit(orderDetail)
      .then((res) => {
        console.log(res);
        var data = res.data.data;
        console.log(data);
        let url = BASE_URL.replace("https://", "");
        window.location.href = `https://www.ap3xtrack.com/2154TCP/7GWPDJ/?source_id=${pixel}&sub1=${url}&sub2=${
          data.orderNu
        }&sub3=Facebook&sub4=${title}&sub5=${BASE_URL + img}&first_name=${
          orderDetail.firstName
        }&last_name=${orderDetail.lastName}&email=${orderDetail.email}&phone=${
          orderDetail.phone
        }`;
        this.toggleReload();
      })
      .catch((err) => {
        this.toggleReload();
      });

    if (window.fbq) {
      let event_id = Math.round(Math.random() * 10000000000);
      window.fbq(
        "track",
        "InitiateCheckout",
        {
          content_category: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "EUR",
          value: orderDetail.amount,
          num_items: 1,
          contents: [
            {
              id: orderDetail.vehicleModel,
              title: orderDetail.vehicleModel,
              quantity: 1,
            },
          ],
        },
        { eventID: event_id }
      );
      let customdata = {
        content_category: orderDetail.vehicleModel,
        content_ids: [orderDetail.vehicleModel],
        currency: "EUR",
        value: orderDetail.amount,
        num_items: 1,
        contents: [
          {
            id: orderDetail.vehicleModel,
            title: orderDetail.vehicleModel,
            quantity: 1,
          },
        ],
      };
      let userdata = {
        em: orderDetail.email,
        ph: orderDetail.phone,
        fn: orderDetail.firstName,
        ln: orderDetail.lastName,
        ct: orderDetail.city,
        st: orderDetail.state,
        zp: orderDetail.zipCode,
        country: orderDetail.country,
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      FackbookAPI.Facebook(
        event_id,
        "InitiateCheckout",
        customdata,
        userdata,
        fbqToken,
        pixel
      ).then((res) => {
        console.log(res);
      });
      // fb
      let url = BASE_URL.replace("https://", "");
      CountPixelEventAPI.countPixelEvent(url, ad, "AddPaymentInfo").then(
        (res) => {
          console.log(res);
        }
      );
    }
  }

  clickToPay() {
    let { payment_intent_id, count, test_drive_package } = this.state;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    orderDetail.test_drive_package = test_drive_package;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    console.log(count);
    if (count === 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "Les achats d'aujourd'hui ont été épuisés!",
        })
      );
      // 24小时内只允许点三次
      let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      let value = "Prohibitd purchase";
      Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
      return;
    }
    count--;
    console.log(count);
    this.setState({ count });
    let ad = "";
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "cutting";
      console.log(ad);
    }
    orderDetail.ad = ad;
    orderDetail.success_url = "/Success";
    orderDetail.cancel_url = "/Fail";
    orderDetail.payment_intent_id = payment_intent_id;
    orderDetail.type = 10;
    orderDetail.curcrency = 1;
    this.toggleReload();
    SubmitPayAPI.submit(orderDetail)
      .then((res) => {
        console.log(res);
        this.toggleReload();
        var data = res.data.data.data;
        console.log(data);
        if (data !== null) {
          let stripePromise = loadStripe(data.api_publishable_key);
          // 自动滑到底部
          setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
          }, 2000);
          let userDetails = {
            name: orderDetail.firstName,
            email: orderDetail.email,
            address: {
              city: orderDetail.city,
              state: orderDetail.state,
              country: orderDetail.country,
              postal_code: orderDetail.zipCode,
              line1: orderDetail.address,
            },
          };
          this.setState({
            stripePromise,
            payment_intent_id: data.payment_intent_id,
            clientSecret: data.clientSecret,
            userDetails,
            url: `${BASE_URL}/success?payment_intent_id=${data.payment_intent_id}&subscriptionId=${data.subscriptionId}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
            // url: `http://localhost:3001/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
          });

          orderDetail.payment_intent_id = data.payment_intent_id;
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        } else {
          this.clickToPay();
        }
      })
      .catch((err) => {
        this.toggleReload();
      });

    if (window.fbq) {
      let event_id = Math.round(Math.random() * 10000000000);
      window.fbq(
        "track",
        "InitiateCheckout",
        {
          content_category: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "EUR",
          value: orderDetail.amount,
          num_items: 1,
          contents: [
            {
              id: orderDetail.vehicleModel,
              title: orderDetail.vehicleModel,
              quantity: 1,
            },
          ],
        },
        { eventID: event_id }
      );
      let customdata = {
        content_category: orderDetail.vehicleModel,
        content_ids: [orderDetail.vehicleModel],
        currency: "EUR",
        value: orderDetail.amount,
        num_items: 1,
        contents: [
          {
            id: orderDetail.vehicleModel,
            title: orderDetail.vehicleModel,
            quantity: 1,
          },
        ],
      };
      let userdata = {
        em: orderDetail.email,
        ph: orderDetail.phone,
        fn: orderDetail.firstName,
        ln: orderDetail.lastName,
        ct: orderDetail.city,
        st: orderDetail.state,
        zp: orderDetail.zipCode,
        country: orderDetail.country,
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      FackbookAPI.Facebook(
        event_id,
        "InitiateCheckout",
        customdata,
        userdata
      ).then((res) => {
        console.log(res);
      });
    }
  }

  showCart() {
    this.setState({ showCart: !this.state.showCart });
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getAddress() {
    this.toggleShow();
    console.log(this.state.zip);
    GetAddressAPI.getAddress(this.state.zip)
      .then((res) => {
        GetAddressAPI.getAddress(this.state.zip, 1).then((res) => {
          var data = res.data.data;
          if (data) {
            const { riBen } = this.state;
            riBen.map((item) => {
              if (item.value === data) {
                this.setState(
                  {
                    province: item,
                  },
                  () => {
                    const {
                      email1,
                      phone1,
                      address,
                      firstName,
                      lastName,
                      province,
                      zip,
                    } = this.state;
                    let next = false;

                    next =
                      email1.trim() === "" ||
                      province === null ||
                      address.trim() === "" ||
                      zip.trim() === "" ||
                      phone1.trim() === "" ||
                      firstName.trim() === "" ||
                      lastName.trim() === "";
                    console.log(province === null);
                    if (!next) {
                      this.setState({ checked: true });
                    }
                  }
                );
                return;
              }
            });
          }
        });
        this.toggleShow();
        console.log(res);
        var data = res.data.data;
        if (data) {
          this.setState({ address: data });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const {
      checked,
      country,
      isAddress,
      isCountry,
      isEmail1,
      isEmail2,
      isFirstName,
      isLastName,
      isPhone1,
      isPhone2,
      isProvince,
      isZip,
      email1,
      phone1,
      address,
      firstName,
      lastName,
      province,
      zip,
      SSN,
      isSSN,
      email2,
      phone2,
      tip,
      riBen,
      showCart,
      num,
      total,
      vehicleModel,
      vehicleModel2,
      vehicleModel3,
      clientSecret,
      stripePromise,
      userDetails,
      url,
      amount,
      title,
      img,
    } = this.state;

    let next = false;

    // next =
    //   email1.trim() === "" ||
    //   province === null ||
    //   address.trim() === "" ||
    //   zip.trim() === "" ||
    //   phone1.trim() === "" ||
    //   firstName.trim() === "" ||
    //   lastName.trim() === "";

    let nextButton = false;

    nextButton =
      email1.trim() === "" ||
      email2.trim() === "" ||
      province === null ||
      address.trim() === "" ||
      zip.trim() === "" ||
      phone1.trim() === "" ||
      phone2.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      (country === "US" ? SSN.trim() === "" : false);

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret,
      appearance,
      locale: "ja",
    };

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="customer-page">
          {/* <HeadBar {...this.props} /> */}
          <div className="shipping-cart" onClick={this.showCart}>
            <div className="left">
              <ShoppingCart size={25} color="rgb(34, 119, 200)" />
              <p> {showCart ? "Hidden" : "Show"} Shopping Cart</p>
              {showCart ? (
                <SolidAllowUp size={15} color="rgb(34, 119, 200)" />
              ) : (
                <SolidAllowDown size={15} color="rgb(34, 119, 200)" />
              )}
            </div>
            <div className="right">¥{total}</div>
          </div>
          <div
            className={`shoppingCart-content ${showCart ? "show-cart" : ""}`}
          >
            <div className="product-row">
              <div className="left">
                <div className="img-box">
                  <img src={img} alt="" />
                  <div className="num">{num}</div>
                </div>
                <div className="title">
                  <div className="name">{title}</div>
                </div>
              </div>
              <div className="price">¥{total}</div>
            </div>
            <div className="subtotal-row">
              <div className="top-row">
                <div className="title">Subtotal :</div>
                <div className="price">¥{total}</div>
              </div>
              <div className="bottom-row">
                <div className="title">
                  <ShippingCart size={24} color="#888888" /> Shipping :
                </div>
                <div className="price">Calculate later</div>
              </div>
            </div>
            <div className="total-row">
              <div className="title">Total:</div>
              <div className="price">
                <span>JPY</span>¥{total}
              </div>
            </div>
          </div>
          <div className="customer-box">
            <div className="title">連絡先</div>
            <div className="info-box">
              <div className="input-row">
                <div className="half">
                  {/* firstname */}
                  <div className="row">
                    <div className="tip-title">名前 例）東京太郎</div>
                    <input
                      type="text"
                      value={firstName || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "firstName");
                      }}
                      onBlur={(e) => this.blurInput(e, "firstName")}
                    />
                  </div>
                </div>
                <div className="half">
                  {/* lastname */}
                  <div className="row">
                    <div className="tip-title">
                      ラストネーム 例）とうきょうたろう
                    </div>
                    <input
                      type="text"
                      value={lastName || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "lastName");
                      }}
                      onBlur={(e) => this.blurInput(e, "lastName")}
                    />
                  </div>
                </div>
              </div>
              <div className="input-row">
                {/* email */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">電子メールアドレス</div>
                    <input
                      type="text"
                      value={email1 || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "email1");
                      }}
                      onBlur={(e) => this.blurInput(e, "email1")}
                    />
                  </div>
                </div>
                {/* phone */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">電話番号</div>
                    <ReactPhoneInput
                      defaultCountry="jp"
                      value={phone1 || ""}
                      flagsImagePath={flagImg}
                      onChange={(e) => this.inputChange(e, "phone1")}
                      onBlur={(e) => this.blurInput(e, "phone1")}
                    />
                  </div>
                </div>
              </div>
              <div className="input-row">
                {/* zip */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">郵便番号</div>
                    <input
                      type="text"
                      value={zip || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "zip");
                      }}
                      onBlur={(e) => this.blurInput(e, "zip")}
                    />
                  </div>
                </div>
              </div>

              {/* 地址检索 */}
              <div className="input-row">
                <div className="search-btn" onClick={this.getAddress}>
                  <p>住所検索</p> <SearchIcon size={16} color="#fff" />{" "}
                </div>
                <div className="search-url">
                  <a
                    href="https://www.post.japanpost.jp/zipcode/"
                    target="_blank"
                  >
                    <AllowRight size={16} color="#000" />
                    <p>郵便番号がわからない方はこちらをご覧ください</p>
                    <UrlIcon size={20} />
                  </a>
                </div>
              </div>
              {/* state */}
              <div className="input-row">
                <div className="half">
                  <div className="row">
                    <div className="tip-title">都道府県</div>
                    {/* <input
                      type="text"
                      value={province || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "province");
                      }}
                      onBlur={(e) => this.blurInput(e, "province")}
                    /> */}
                    <Select
                      options={riBen}
                      value={this.state.province}
                      placeholder="都道府県選択"
                      isClearable={true}
                      onChange={(e) => {
                        this.inputChange(e, "province");
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* address */}
              <div className="input-row">
                <div className="full">
                  <div className="row">
                    <div className="tip-title">住所</div>
                    <input
                      type="text"
                      value={address || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "address");
                      }}
                      onBlur={(e) => this.blurInput(e, "address")}
                    />
                  </div>
                </div>
              </div>
              {checked ? (
                <div className="country-box">
                  <div className="input-row">
                    <div className="half">
                      <div className="row">
                        <div className="tip-title">国または地域</div>
                        <ReactFlagsSelect
                          selected={this.state.country}
                          onSelect={(code) => this.setSelected(code)}
                        />
                      </div>
                    </div>
                    {country === "US" ? (
                      <div className="half">
                        <div className="row">
                          <div className="tip-title">SSN</div>
                          <input
                            type="text"
                            value={SSN || ""}
                            className="input"
                            onChange={(e) => {
                              this.inputChange(e, "SSN");
                            }}
                            onBlur={(e) => this.blurInput(e, "SSN")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className="input-row">
                      <div className="half">
                        <div className="row">
                          <div className="tip-title">
                            ドライバーのEメールアドレス
                          </div>
                          <input
                            type="text"
                            value={email2 || ""}
                            className="input"
                            onChange={(e) => {
                              this.inputChange(e, "email2");
                            }}
                            onBlur={(e) => this.blurInput(e, "email2")}
                          />
                        </div>
                      </div>
                      <div className="half">
                        <div className="row">
                          <div className="tip-title">運転手の電話番号</div>
                          <ReactPhoneInput
                            defaultCountry="us"
                            value={phone2 || ""}
                            flagsImagePath={flagImg}
                            onChange={(e) => this.inputChange(e, "phone2")}
                            onBlur={(e) => this.blurInput(e, "phone2")}
                          />
                        </div>
                      </div>
                    </div> */}
                </div>
              ) : (
                ""
              )}
              <div className="next-model">
                <div className={`permission ${next ? "" : "show-permission"}`}>
                  <div className="permission-row">
                    <Checkbox
                      checked={checked}
                      onChange={(e) => this.handleChange(e)}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    私は、上記の情報が真実かつ有効であることを誓います。
                  </div>
                </div>
              </div>
              <div className="input-row">
                <div className="tip">{tip}</div>
              </div>
              <div className="input-row">
                {nextButton ? (
                  <React.Fragment>
                    {clientSecret !== "" ? (
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm userDetails={userDetails} url={url} />
                      </Elements>
                    ) : (
                      <div className="btn" onClick={this.clickToPay}>
                        クレジットカードでのお支払い
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div className="check" onClick={this.clickToCheck}>
                    クレジットカードでのお支払い
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Customer;
