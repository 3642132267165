import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";
import { STORY_URL } from "../constants/ApiEndpointConstants";

export const getStoryList = (page, per_page) => {
  return api.post(`${STORY_URL}` + `${ApiEndpointConstants.STORY_LIST}`, {
    page,
    per_page,
  });
};
