import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

import { STORY_URL } from "../constants/ApiEndpointConstants";

export const getCommentListAPI = (page, pet_page, id) => {
  return api.post(`${STORY_URL}` + `${ApiEndpointConstants.COMMENT_LIST}`, {
    page,
    pet_page,
    tieziid: id,
  });
};
