import React from "react";

const PhotoIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#4A9ED8",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="67407"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M955.712 128a65.152 65.152 0 0 1 42.688 17.088 60.8 60.8 0 0 1 25.6 51.2v640a65.152 65.152 0 0 1-17.088 42.688 91.52 91.52 0 0 1-51.2 17.088H68.288a91.52 91.52 0 0 1-51.2-17.088 91.52 91.52 0 0 1-17.088-51.2V196.288A65.152 65.152 0 0 1 17.088 153.6a60.8 60.8 0 0 1 51.2-25.6z m-665.6 153.6A96.896 96.896 0 0 0 221.888 256a96.896 96.896 0 0 0-68.288 25.6A96.896 96.896 0 0 0 128 349.888a96.896 96.896 0 0 0 25.6 68.288 96.896 96.896 0 0 0 68.288 25.6 96.896 96.896 0 0 0 68.288-25.6 96.896 96.896 0 0 0 25.6-68.288A96.896 96.896 0 0 0 290.112 281.6zM896 768l-256-384-230.4 298.688L256 571.712 128 768z"
      fill={color}
      p-id="67408"
    ></path>
  </svg>
);

export default PhotoIcon;
