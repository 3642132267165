import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Home from "./components/Home";
import Loading from "./components/common/Loading";
import Bmw from "./components/Bmw";
import Model from "./components/Model";
import Customer from "./components/Customer";
import Document from "./components/Document";
import Info from "./components/Info";
import Selfie from "./components/Selfie";
import SelfieInfo from "./components/SelfieInfo";
import Plan from "./components/Plan";
import Order from "./components/Order";
import * as GetPixelAPI from "./apis/GetPixelAPI";
import * as FacebookAPI from "./apis/FacebookAPI";
import queryString from "query-string";
import Success from "./components/Success";
import Fail from "./components/Fail";
import Share from "./components/Share";
import Contact from "./components/Contact";
import { url } from "./constants/ApiEndpointConstants";

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    GetPixelAPI.getFbPixel(url).then((res) => {
      console.log(res);
      var data = res.data.data;
      if (data && data.pixel) {
        const pixel = data.pixel;
        let head = document.querySelector("head");
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixel}');
        fbq('track', 'PageView');`;

        head.appendChild(script);
        let noscript = document.createElement("noscript");
        noscript.innerHTML = `< img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1" />`;
        head.appendChild(noscript);

        let customdata = {
          content_ids: [],
          contents: [],
          content_name: "",
          content_category: "",
          currency: "JPY",
          content_type: "",
        };
        let userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        let event_id = Math.round(Math.random() * 10000000000);
        FacebookAPI.Facebook(
          event_id,
          "PageView",
          customdata,
          userdata,
          data.fbqToken,
          data.pixel
        ).then((res) => {
          console.log(res);
        });
        localStorage.setItem("tgid", JSON.stringify(data.tgid));
        localStorage.setItem("fbqToken", JSON.stringify(data.fbqToken));
        localStorage.setItem("pixel", JSON.stringify(data.pixel));
        localStorage.setItem("amount", JSON.stringify(data.amount));
        localStorage.setItem("amount_fast", JSON.stringify(data.amount_fast));
        localStorage.setItem("pixel", JSON.stringify(data.pixel));
      }
    });
    // 获取浏览器当前路径
    let location = window.location.href;
    let result = location.substring(location.indexOf("?"));
    let arr = queryString.parse(result);
    let ad = arr.ad || "test";
    localStorage.setItem("ad", JSON.stringify(ad));
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route path="/Contact" component={Contact}></Route>
            <Route path="/Fail" component={Fail}></Route>
            <Route path="/Success" component={Success}></Route>
            <Route path="/Order" component={Order}></Route>
            <Route path="/Plan" component={Plan}></Route>
            <Route path="/SelfieInfo" component={SelfieInfo}></Route>
            <Route path="/Selfie" component={Selfie}></Route>
            <Route path="/Info" component={Info}></Route>
            <Route path="/Document" component={Document}></Route>
            <Route path="/Customer" component={Customer}></Route>
            <Route path="/Model" component={Model}></Route>
            <Route path="/BMW" component={Bmw}></Route>
            <Route path="/" component={Share}></Route>
            {/* <Route path="/" component={Home}></Route> */}
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
export default App;
