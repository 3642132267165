import React, { Component } from "react";
import HeadBar from "./common/HeadBar";
import "../style/selfieInfo.scss";
import Kuang from "../icon/Kuang";
import People from "../icon/People";
import Photo from "../icon/Photo";
import api from "../apis/API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import * as UploadAPI from "../apis/UploadAPI";
import axios from "axios";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import RePhoto from "../icon/RePhoto";
import * as FacebookAPI from "../apis/FacebookAPI";
import * as UpdateOrderAPI from "../apis/UpdateOrderAPI";
import Loading from "./common/Loading";

class SelfieInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: false, //是否授权
      recording: false, // 是否正在录制视频的状态
      videoURL: null, // 录制完成后视频的URL
      error: null, // 错误信息
      remainingTime: 8, //倒计时
      clickDown: false,
      videoReceiveURL: "",
      loadingShow: false,
    };
    this.startRecording = this.startRecording.bind(this);
    this.requestCameraPermission = this.requestCameraPermission.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
    this.resetRecordingState = this.resetRecordingState.bind(this);
    this.createVideo = this.createVideo.bind(this);
    this.removeAllVideos = this.removeAllVideos.bind(this);
    this.routeOrder = this.routeOrder.bind(this);
    this.toggleShow = this.toggleShow.bind(this);

    // 创建用于引用视频元素的引用
    this.videoRef = React.createRef();
    this.receiveVideoRef = React.createRef();
    // 创建用于保存录制视频数据的数组
    this.chunks = [];
    // 创建一个MediaRecorder实例的引用
    this.mediaRecorder = null;
  }

  // 请求权限
  requestCameraPermission() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.setState({ video: true });
        this.videoRef.current.srcObject = stream;
        // mediaStreamTrack = stream;
      })
      .catch((error) => {
        console.error("Error accessing media devices: ", error);
        this.setState({
          error:
            "Error accessing media devices. Please make sure your camera is connected and you have granted permission to access it.",
        });
      });
  }

  // 重置
  resetRecordingState() {
    this.chunks = []; // 清空录制数据数组
    clearInterval(this.intervalId); // 清除倒计时
    this.setState({
      recording: false,
      remainingTime: 8, // 重新设置剩余时间为8秒
      video: false,
      videoReceiveURL: "",
    });
    //
  }

  // 开始录制
  startRecording = () => {
    const stream = this.videoRef.current.srcObject;
    console.log(stream);
    if (!stream) {
      console.error("No video stream available");
      return;
    }
    this.setState({ clickDown: true });
    this.mediaRecorder = new MediaRecorder(stream);

    if (this.videoRef) {
      this.videoRef.current.srcObject = stream;
      this.videoRef.current.play();
    }
    // 监听每个数据块的生成，并将其添加到 chunks 数组中
    this.mediaRecorder.ondataavailable = (event) => {
      this.chunks.push(event.data);
    };

    this.mediaRecorder.onstop = () => {
      console.log("调用了Stop-------------");
      let blob = new Blob(this.chunks, { type: "video/webm" });
      let videoURL = URL.createObjectURL(blob);
      this.setState({ videoURL, recording: false });
      this.uploadVideo(blob);
      this.chunks = [];
    };

    this.mediaRecorder.start();
    this.setState({ recording: true });
    this.intervalId = setInterval(() => {
      let remainingTime = this.state.remainingTime;
      if (remainingTime > 0) {
        remainingTime = this.state.remainingTime - 1;
      } else {
        remainingTime = 0;
      }
      if (remainingTime === 0) {
        // 检查是否剩余时间为0
        console.log("---------");
        this.stopRecording();
        this.setState({ clickDown: false });
      }
      this.setState({ remainingTime });
    }, 1000);
  };

  // 停止录制
  stopRecording() {
    clearInterval(this.intervalId);
    if (this.mediaRecorder && this.state.recording) {
      this.mediaRecorder.stop();
      // 停止媒体流
      const stream = this.videoRef.current.srcObject;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      this.setState({ recording: false });
      this.chunks = [];
    }
  }

  // 上传视频
  uploadVideo = (videoBlob) => {
    this.toggleShow();
    const formData = new FormData();
    formData.append("file", videoBlob);
    axios({
      url: BASE_URL + ApiEndpointConstants.UPLOAD,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res);
        var status = res.status;
        if (status === 200) {
          var url = res.data.data.url;
          this.setState({ videoReceiveURL: url }, () => {
            this.toggleShow();
            this.removeAllVideos();
            this.createVideo(url);
            let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
            let ad = "";
            if (localStorage.getItem("ad")) {
              ad = JSON.parse(localStorage.getItem("ad")) || "";
              console.log(ad);
            }
            orderDetail.ad = ad;
            orderDetail.userSelfPictureOrVideo = url;
            UpdateOrderAPI.updateOrderInformation(orderDetail)
              .then((res) => {})
              .catch((err) => {});
          });
        }
      })
      .catch((error) => {
        console.error("Error uploading video: ", error);
        this.toggleShow();
      });
  };

  // 移除节点下的所有视频元素
  removeAllVideos() {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((video) => {
      video.remove();
    });
  }

  //创建新的元素
  createVideo(src) {
    const videoElement = document.createElement("video");
    videoElement.src = BASE_URL + src;
    videoElement.autoplay = true;
    videoElement.controls = true;
    videoElement.type = "video/mp4";
    videoElement.playsInline = true;
    this.receiveVideoRef.current = videoElement;
    document.querySelector(".video-box").appendChild(videoElement);
  }

  routeOrder() {
    let { history, location } = this.props;
    let { videoReceiveURL } = this.state;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Order",
        search: location.search,
      });
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    orderDetail.userSelfPictureOrVideo = videoReceiveURL;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    // fbq;
    if (window.fbq) {
      let event_id = Math.round(Math.random() * 10000000000);
      window.fbq(
        "track",
        "AddPaymentInfo",
        {
          content_name: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "JPY",
          value: "500",
          contents: [
            {
              id: orderDetail.vehicleModel,
              title: orderDetail.vehicleModel,
              quantity: 1,
            },
          ],
        },
        { eventID: event_id }
      );
      let customdata = {
        content_name: orderDetail.vehicleModel,
        content_ids: [orderDetail.vehicleModel],
        currency: "JPY",
        value: "500",
        contents: [
          {
            id: orderDetail.vehicleModel,
            title: orderDetail.vehicleModel,
            quantity: 1,
          },
        ],
      };
      let userdata = {
        em: "",
        ph: "",
        fn: "",
        ln: "",
        ct: "",
        st: "",
        zp: "",
        country: "",
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      FacebookAPI.Facebook(
        event_id,
        "AddPaymentInfo",
        customdata,
        userdata
      ).then((res) => {
        console.log(res);
      });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  render() {
    const { video, remainingTime, clickDown, videoReceiveURL } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="selfieInfo-page">
          <HeadBar {...this.props} />
          <div className="selfieInfo-box">
            <h2>セルフィー動画を撮る</h2>
            {video ? (
              <div className="camera-box">
                <div className="video-box">
                  {videoReceiveURL === "" ? (
                    <video
                      ref={this.videoRef}
                      autoPlay
                      playsInline={true}
                    ></video>
                  ) : (
                    <video
                      ref={this.receiveVideoRef}
                      autoPlay={true}
                      type="video/mp4"
                      controls
                      className="receive-video"
                      muted={true}
                      playsInline={true}
                    ></video>
                  )}
                </div>
                {videoReceiveURL === "" ? (
                  <React.Fragment>
                    {clickDown ? (
                      <div className="remaining">00 : 0{remainingTime}</div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`start ${clickDown ? "unClick" : ""}`}
                      onClick={this.startRecording}
                    >
                      開始
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="reload">
                      <RePhoto
                        size={40}
                        className="reload-icon"
                        onClick={this.resetRecordingState}
                      />
                    </div>
                    <div className="btn" onClick={this.routeOrder}>
                      次のページ
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : (
              <React.Fragment>
                <div
                  className="takePhone-box"
                  onClick={this.requestCameraPermission}
                >
                  <Kuang size={340} color="#6C8EEF" />
                  <People size={150} color="#E3E8EE" className="people" />
                  <div className="animate"></div>
                  <div className="video-box">
                    <Photo size={30} color="rgb(92, 94, 98)" />
                    <p className="tip">クリックしてセルフィー動画を撮る</p>
                  </div>
                </div>
                <video src=""></video>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SelfieInfo;
