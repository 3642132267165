import React from "react";

const Line = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#000",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="50248"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512.013 75C250.766 75 39 241.635 39 447.175 39 638.1 221.798 795.348 457.187 816.77a28.802 28.802 0 0 1 19.224 12.549c10.448 17.196 3.022 53.833-5.09 94.914-8.114 41.076 36.005 19.76 45.766 15 7.771-3.769 207.754-116.462 326.812-226.97C930.962 644.746 985 550.924 985 447.187 985.03 241.635 773.247 75 512.013 75zM355 543.99c-0.125 13.927-11.545 25.122-25.513 25.01h-86.763C227.41 569 207 563.723 207 538.986V354.01a25.188 25.188 0 0 1 7.564-17.773A25.324 25.324 0 0 1 232.513 329h5.108a25.328 25.328 0 0 1 17.95 7.237 25.192 25.192 0 0 1 7.563 17.773v159.97h66.34c13.972-0.12 25.4 11.075 25.526 25.006v5.004z m71-5.342C426 552.652 414.581 564 400.5 564c-14.085 0-25.5-11.348-25.5-25.352V359.352C375 345.35 386.415 334 400.5 334c14.081 0 25.5 11.35 25.5 25.352v179.296z m222.93-0.021c-1.429 13.309-12.064 23.763-25.395 24.962a28.745 28.745 0 0 1-29.934-14.313l-81.827-114.744v104.066c-0.33 13.783-11.597 24.783-25.388 24.783-13.785 0-25.057-11-25.386-24.783V358.946a25.156 25.156 0 0 1 7.524-17.73A25.16 25.16 0 0 1 486.38 334a32.116 32.116 0 0 1 28.788 18.278c7.874 11.623 83.026 116.388 83.026 116.388v-109.72c0.341-13.783 11.614-24.779 25.404-24.779s25.062 10.996 25.403 24.78v179.65l-0.07 0.03z m153.436-114.634c8.949 0 17.217 4.767 21.692 12.503a24.969 24.969 0 0 1 0 25.008 25.057 25.057 0 0 1-21.692 12.503h-66.264v39.988h66.26a25.057 25.057 0 0 1 22.18 12.33 24.958 24.958 0 0 1 0 25.344 25.058 25.058 0 0 1-22.18 12.324h-99.383a22.721 22.721 0 0 1-16.165-6.482A22.629 22.629 0 0 1 680 541.513v-185.01a22.64 22.64 0 0 1 6.81-16.01 22.734 22.734 0 0 1 16.169-6.486h99.387a25.063 25.063 0 0 1 22.18 12.324 24.978 24.978 0 0 1 0 25.345 25.071 25.071 0 0 1-22.18 12.329h-66.264v40.004h66.26l0.004-0.016z"
      fill="#00C300"
      p-id="50249"
    ></path>
  </svg>
);


export default Line;