import React, { Component } from "react";
import "../style/success.scss";
import BMW from "../image/BMW.SVG";
import Call from "../icon/Call";
import Message from "../icon/Message";
import Telegram from "../icon/Telegram";
import Twtter from "../icon/Twtter";
import Facebook from "../icon/Facebook";
import queryString from "query-string";
import * as CustomStatusAPI from "../apis/CustomStatusAPI";
import * as FacebookAPI from "../apis/FacebookAPI";
import MobileDetectUtil from "../utils/MobileDetectUtil";
import Line from "../icon/Line";

const invitation_text = "BMWを1年間無料で手に入れる方法~";
const HTTP_REQUEST_URL =
  window.location.protocol + "//" + window.location.host + "?ad=share";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      share: [
        {
          title: "Line",
          url: `https://line.me/R/share?text=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "WhatsApp",
          url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${invitation_text} ${HTTP_REQUEST_URL}`
          )}&url=${encodeURIComponent(HTTP_REQUEST_URL)}`,
          isClick: false,
        },
        {
          title: "Sms",
          url: `sms:${
            MobileDetectUtil.judgeClient() === "iOS" ? "&" : "?"
          }body=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Telegram",
          url: `https://telegram.me/share/url?text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Twitter",
          url: `https://twitter.com/intent/tweet?lang=en&text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Facebook",
          url: `https://www.facebook.com/dialog/share?app_id=407682420960&display=popup&href=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
      ],
      shareClick: null,
      show: false,
    };
    this.routeHome = this.routeHome.bind(this);
    this.clivkToShare = this.clivkToShare.bind(this);
  }

  componentDidMount() {
    let { location } = this.props;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    let TimeOut = setInterval(() => {
      if (window.fbq) {
        console.log("fbq");
        clearInterval(TimeOut);
        let event_id = Math.round(Math.random() * 10000000000);
        window.fbq(
          "track",
          "Purchase",
          {
            content_name: orderDetail.vehicleModel,
            content_ids: [orderDetail.vehicleModel],
            currency: "JPY",
            contents: [
              {
                title: orderDetail.vehicleModel,
                id: orderDetail.vehicleModel,
                quantity: "1",
              },
            ],
            value: 500,
          },
          { eventID: event_id }
        );
        let customdata = {
          content_name: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "JPY",
          contents: [
            {
              title: orderDetail.vehicleModel,
              id: orderDetail.vehicleModel,
              quantity: "1",
            },
          ],
          value: 200,
        };
        let userdata = {
          em: orderDetail.email,
          ph: orderDetail.phone,
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        FacebookAPI.Facebook(event_id, "Purchase", customdata, userdata).then(
          (res) => {
            console.log(res);
            // 清楚缓存;
            localStorage.removeItem("orderDetail");
          }
        );
      }
    }, 3000);
    this.setState({ product: orderDetail.vehicleModel });
    if (localStorage.getItem("share")) {
      let shareClick = JSON.parse(localStorage.getItem("share"));
      this.setState({
        shareClick: parseInt(shareClick),
      });
    }
    if (location.search !== "") {
      let arr = queryString.parse(location.search);
      console.log(arr);
      this.setState({ email: arr.email, phone: arr.phone });

      CustomStatusAPI.customStatus(
        arr.payment_intent_id,
        arr.status,
        arr.c_site_url,
        arr.subscriptionId
      ).then((res) => {
        console.log(res);
        var data = res.data.data;
        console.log(data);
        if (data.status === "succeeded") {
        }
      });
    }
  }

  routeHome() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Model",
        search: location.search,
      });
  }

  clivkToShare(index) {
    let { share, shareClick } = this.state;
    if (shareClick === 2) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ show: false });
      }, 1000);
      localStorage.setItem("share", JSON.stringify(shareClick));
      setTimeout(() => {
        shareClick = parseInt(shareClick) + 1;
        this.setState({ shareClick: parseInt(shareClick) });
      }, 1000);
      return;
    }
    if (shareClick === 4) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ show: false });
      }, 1000);
      localStorage.setItem("share", JSON.stringify(shareClick));
      setTimeout(() => {
        shareClick = parseInt(shareClick) + 1;
        this.setState({ shareClick: parseInt(shareClick) });
      }, 1000);
      return;
    }
    if (shareClick === 7) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ show: false });
      }, 1000);
      return;
    }
    window.open(share[index].url, "_blank");
    console.log(shareClick);
    if (shareClick === null) {
      shareClick = 1;
    } else {
      if (shareClick === 7) {
        this.setState({ show: true });
        setTimeout(() => {
          this.setState({ show: false });
        }, 3000);
        return;
      }
      shareClick = parseInt(shareClick) + 1;
    }

    localStorage.setItem("share", JSON.stringify(shareClick));
    setTimeout(() => {
      this.setState({ shareClick: parseInt(shareClick) });
    }, 1000);
  }

  render() {
    const { email, phone, shareClick, show } = this.state;
    return (
      <React.Fragment>
        <div className={`share-mask ${show ? "show" : ""}`}>
          <div className="share-textBox">
            <div className="title">
              {shareClick === 7 ? "成功を分かち合う！" : ""}
            </div>
            <div className="desc">
              {shareClick === 2
                ? "別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。"
                : ""}
              {shareClick === 4 ? "グループチャットも共有できる" : ""}
            </div>
          </div>
        </div>
        <div className="success-page">
          <div className="head-logo">
            <img src={BMW} alt="" />
          </div>
          <p>
            ご注文ありがとうございました! 電子メールを送信します
            <span>{email}</span> 注文状況を確認する。 同時に
            同時にお客様の電話番号を <span>{phone}</span> 5営業日以内
            営業日以内にご回答ください。
          </p>
          <p>
            ソーシャルメディアで共有し、友人を試乗に招待することで、情報の見直しをスピードアップできます。
            あなたの情報の見直しをスピードアップします！
          </p>
          <div className="icon-row">
            <Line
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(0)}
            />
            {/* <div className="icon li-icon">
              <Call
                size={70}
                className="li-icon"
                onClick={() => this.clivkToShare(1)}
              />
            </div> */}
            <Message
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(2)}
            />
            {/* <Telegram
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(3)}
            />
            <Twtter
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(4)}
            />
            <Facebook
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(5)}
            /> */}
          </div>
          {shareClick !== null ? (
            <div className="loading-line">
              <div
                className={`bgc-line 
                ${shareClick === 1 && "w20"} 
                ${shareClick === 2 && "w40"}
                ${shareClick === 3 && "w40"} 
                ${shareClick === 4 && "w60"} 
                ${shareClick === 5 && "w60"} 
                ${shareClick === 6 && "w80"} 
                ${shareClick === 7 && "w100"}`}
              >
                {shareClick === 1 && "20%"}
                {shareClick === 2 && "40%"}
                {shareClick === 3 && "40%"}
                {shareClick === 4 && "60%"}
                {shareClick === 5 && "60%"}
                {shareClick === 6 && "80%"}
                {shareClick === 7 && "100%"}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="btn" onClick={this.routeHome}>
            ホームへ戻る
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Success;
