import React from "react";

const UrlIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#000",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="5790"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M840.67 264C906.59 264 960 317.485 960 383.429V640.57C960 706.515 906.59 760 840.67 760H593.09v-96H840.67c12.87 0 23.33-10.474 23.33-23.429V383.43C864 370.474 853.54 360 840.67 360H593v-96h247.67zM432 264v96H183.48c-12.975 0-23.48 10.497-23.48 23.429V640.57C160 653.503 170.505 664 183.48 664h248.016v96H183.48C117.5 760 64 706.538 64 640.571V383.43C64 317.462 117.501 264 183.48 264H432z"
      fill={color}
      p-id="5791"
    ></path>
    <path
      d="M332 464m8 0l344 0q8 0 8 8l0 80q0 8-8 8l-344 0q-8 0-8-8l0-80q0-8 8-8Z"
      fill={color}
      p-id="5792"
    ></path>
  </svg>
);

export default UrlIcon;
