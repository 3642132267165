import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import cookie from "react-cookies";

export const Facebook = (
  event_id,
  eventname,
  customdata,
  userdata,
  fbqToken,
  pixel,
  orderNu
) => {
  let _fbc = cookie.load("_fbc", { path: "/" });
  let _fbp = cookie.load("_fbp", { path: "/" });
  _fbc = _fbc ? _fbc : "";
  _fbp = _fbp ? _fbp : "";
  userdata["fbc"] = _fbc;
  userdata["fbp"] = _fbp;

  if (!userdata.client_ip_address) {
    delete userdata.client_ip_address;
  }
  if (!userdata.client_user_agent) {
    delete userdata.client_user_agent;
  }

  return api.post(`${ApiEndpointConstants.FACEBOOK}`, {
    event_id: event_id,
    eventname: eventname,
    customdata: customdata,
    userdata: userdata,
    fbqToken: fbqToken,
    pixel: pixel,
    orderNu: orderNu,
  });
};
