import React from "react";

const ReadIcon = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = "#979797",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="99621"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M787.392 230.528a32 32 0 0 1 6.08 44.864l-341.376 448a32 32 0 0 1-48.64 2.688l-170.624-179.2a32 32 0 0 1 46.336-44.16l144.768 152 318.592-418.112a32 32 0 0 1 44.864-6.08z"
      fill={color}
      p-id="99622"
    ></path>
  </svg>
);

export default ReadIcon;