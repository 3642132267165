import React, { Component } from "react";
import "../style/contact.scss";
import PhotoIcon from "../icon/PhotoIcon";
import avatar from "../image/avatar.jpg";
import customer from "../image/customer.jpeg";
import ReadIcon from "../icon/ReadIcon";
import ReadedIcon from "../icon/ReadedIcon";
import wxSend from "../image/wxSend.mp3";
import wxtip from "../image/wxtip.mp3";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="contact-page">
          <div className="send-box">
            <div className="server-title">
              Customer Service Kavita at your service
            </div>
            <div className="left-message">
              <div className="avatar">
                <img src={avatar} alt="" />
              </div>
              <div className="text-box">
                <p>
                  恭喜您获得<span> Xiaomi Uniblade Trimmer </span>
                  免费使用资格，奖品是免费送货上门。我们将优先从最近仓库发货到您的地址,确保7-10内交货,请先点击下方按钮填写您的收货地址。
                </p>
                <div className="click-btn">
                  Click to enter your delivery address
                </div>
              </div>
            </div>
            <div className="right-message">
              <div className="text-box">
                <p>还有活动吗</p>
                <div className="icon">
                  <ReadIcon size={20} />
                </div>
              </div>
              <div className="avatar">
                <img src={customer} alt="" />
              </div>
            </div>
          </div>
          <div className="sendEmail">
            <div className="image-file">
              <PhotoIcon size={40} />
              <input type="file" accept="image/*" className="file-input" />
            </div>
            <input type="text" className="input-text" />
            <div className="send-btn">Send</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
