import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const countPixelEvent = (url, ad, eventname) => {
  return api.post(`https://www.fbpro.info/api/Events/addevent`, {
    url,
    ad,
    eventname,
  });
};
