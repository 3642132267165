import React, { Component } from "react";
import "../style/model.scss";
import HeadBar from "./common/HeadBar";
import i5M60 from "../image/bmw-i5-m60.png";
import ixM60 from "../image/bmw-ix-m60.png";
import i4 from "../image/bmw-i4.png";
import bmw3 from "../image/bmw3.png";
import * as FacebookAPI from "../apis/FacebookAPI";

class Model extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [
        {
          title: "BMW i5 M60 xDrive Touring",
          choose: true,
          img: i5M60,
          disabled: false,
        },
        {
          title: "BMW 3 Series Sedan",
          choose: false,
          img: bmw3,
          disabled: false,
        },
        { title: "BMW iX M60", choose: false, img: ixM60, disabled: true },
        { title: "BMW i4", choose: false, img: i4, disabled: true },
      ],
      vehicleModel: "BMW i5 M60 xDrive Touring",
    };
    this.changeSelect = this.changeSelect.bind(this);
    this.routeCustomer = this.routeCustomer.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { vehicleModel } = this.state;
    // fbq;
    let TimeOut = setInterval(() => {
      if (window.fbq) {
        clearInterval(TimeOut);
        let event_id = Math.round(Math.random() * 10000000000);
        window.fbq(
          "track",
          "viewContent",
          {
            content_name: vehicleModel,
            content_ids: [vehicleModel],
            currency: "JPY",
            content_type: "test-drive",
            content_category: "car_test_drive",
            contents: [
              {
                title: vehicleModel,
                id: vehicleModel,
                quantity: "1",
              },
            ],
            value: "500",
          },
          { eventID: event_id }
        );
        let customdata = {
          content_name: vehicleModel,
          content_ids: [vehicleModel],
          currency: "JPY",
          content_type: "test-drive",
          content_category: "car_test_drive",
          contents: [
            {
              title: vehicleModel,
              id: vehicleModel,
              quantity: "1",
            },
          ],
          value: "500",
        };
        let userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        FacebookAPI.Facebook(
          event_id,
          "viewContent",
          customdata,
          userdata
        ).then((res) => {
          console.log(res);
        });
      }
    }, 3000);
  }

  changeSelect(index) {
    let { cartList } = this.state;
    cartList.map((item, i) => {
      if (index > 1) {
        return;
      }
      item.choose = false;
      if (i === index) {
        item.choose = true;
        this.setState({ vehicleModel: item.title });
        // fbq;
        if (window.fbq) {
          window.fbq("track", "CustomizeProduct", {
            content_name: item.title,
            content_ids: [item.title],
          });
          let customdata = {
            content_name: item.title,
            content_ids: [item.title],
          };
          let userdata = {
            em: "",
            ph: "",
            fn: "",
            ln: "",
            ct: "",
            st: "",
            zp: "",
            country: "",
            external_id: "",
            client_ip_address: "",
            client_user_agent: "",
            fbc: "",
            fbp: "",
            lead_id: "",
            anon_id: "",
            madid: "",
            page_id: "",
            page_scoped_user_id: "",
            ctwa_clid: "",
            ig_account_id: "",
            ig_sid: "",
          };
          let event_id = Math.round(Math.random() * 10000000000);
          FacebookAPI.Facebook(
            event_id,
            "CustomizeProduct",
            customdata,
            userdata
          ).then((res) => {
            console.log(res);
          });
        }
      }
    });
    this.setState({ cartList });
  }

  routeCustomer() {
    let { history, location } = this.props;
    let { vehicleModel } = this.state;
    let orderDetail = {};
    orderDetail.vehicleModel = vehicleModel;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    const search = location.search;
    history &&
      history.push({
        pathname: "/Customer",
        search: location.search,
      });
  }

  render() {
    const { cartList } = this.state;
    return (
      <React.Fragment>
        <div className="model-page">
          <HeadBar {...this.props} />
          <div className="model-box">
            <div className="title">デモドライブのご予約</div>
            <div className="detail">
              ストレスのないドライブを体験してください。車内を探検し、電気自動車の利点を学び、パーソナライズされた充電プランを手に入れましょう。有効な日本の運転免許証をお持ちの18歳以上の方に限ります。
            </div>
            <div className="choose-box">
              <div className="left">
                {cartList.map((item, index) => {
                  return (
                    <div
                      className={item.disabled ? "disabled" : ""}
                      key={index}
                    >
                      <li
                        className={item.choose ? "active" : ""}
                        onClick={() => this.changeSelect(index)}
                      >
                        {item.title}
                      </li>
                    </div>
                  );
                })}
              </div>
              <div className="right">
                {cartList.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item.choose ? <img src={item.img} /> : ""}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="btn" onClick={this.routeCustomer}>
              次のページ
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Model;
