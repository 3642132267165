import React, { Component } from "react";
import "../style/info.scss";
import HeadBar from "./common/HeadBar";
import idCard from "../image/ID-card.png";
import cardBack from "../image/document_back.png";
import { Radio } from "antd";
import * as UploadAPI from "../apis/UploadAPI";
import Loading from "./common/Loading";
import BWM from "../icon/Bmw";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as FacebookAPI from "../apis/FacebookAPI";
import Order from "./Order";
import * as UpdateOrderAPI from "../apis/UpdateOrderAPI";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      cardList: [
        { title: "Identity card", checked: true },
        { title: "Driver's license", checked: false },
        { title: "Passport", checked: false },
      ],
      maskList: [{}, {}, {}, {}, {}, {}, {}, {}],
      documentFront: "",
      documentBack: "",
      identityType: "Identity card",
    };
    this.changeChecked = this.changeChecked.bind(this);
    this.upload = this.upload.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.routeSelfie = this.routeSelfie.bind(this);
  }

  componentDidMount() {
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    if (orderDetail) {
      this.setState({
        identityType:
          orderDetail.identityType && orderDetail.identityType !== ""
            ? orderDetail.identityType
            : "Identity card",
        documentFront:
          orderDetail.documentFront && orderDetail.documentFront !== ""
            ? orderDetail.documentFront
            : "",
        documentBack:
          orderDetail.documentBack && orderDetail.documentBack !== ""
            ? orderDetail.documentBack
            : "",
      });
    }
  }

  changeChecked(index) {
    let { cardList } = this.state;
    cardList.map((item, itemIndex) => {
      item.checked = false;
      if (index === itemIndex) {
        item.checked = true;
        this.setState({ identityType: item.title });
      }
    });
    this.setState({ cardList });
  }

  upload(e, type) {
    var _that = this;
    [e.target.files[0]].forEach((fileItem, fileIndex) => {
      if (fileIndex > 0) return;
      let reads = new FileReader();
      reads.readAsDataURL(fileItem);
      reads.onload = function (e) {
        const imgUrl = this.result;
        _that.toggleShow();
        UploadAPI.upload(imgUrl).then((res) => {
          _that.toggleShow();
          var url = res.data.data.url;
          switch (type) {
            case "front":
              _that.setState({ documentFront: url }, () => {
                let orderDetail = JSON.parse(
                  localStorage.getItem("orderDetail")
                );
                let ad = "";
                if (localStorage.getItem("ad")) {
                  ad = JSON.parse(localStorage.getItem("ad")) || "";
                  console.log(ad);
                }
                orderDetail.ad = ad;
                orderDetail.documentFront = url;
                _that.toggleShow();
                UpdateOrderAPI.updateOrderInformation(orderDetail).then(
                  (res) => {
                    _that.toggleShow();
                  }
                );
              });
              break;
            case "back":
              _that.setState({ documentBack: url }, () => {
                let orderDetail = JSON.parse(
                  localStorage.getItem("orderDetail")
                );
                let ad = "";
                if (localStorage.getItem("ad")) {
                  ad = JSON.parse(localStorage.getItem("ad")) || "";
                  console.log(ad);
                }
                orderDetail.ad = ad;
                orderDetail.documentBack = url;
                _that.toggleShow();
                UpdateOrderAPI.updateOrderInformation(orderDetail)
                  .then((res) => {
                    _that.toggleShow();
                    var data = res.data.data;
                    if (data) {
                      // console.log(data);
                      // orderDetail.id = data.id;
                      // console.log(orderDetail.id);
                      localStorage.setItem(
                        "orderDetail",
                        JSON.stringify(orderDetail)
                      );
                    }
                  })
                  .catch((err) => {
                    this.toggleShow();
                  });
              });
              break;
            default:
              break;
          }
        });
      };
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  routeSelfie() {
    let { location, history } = this.props;
    let { identityType, documentBack, documentFront } = this.state;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Selfie",
        search: location.search,
      });
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    orderDetail.documentFront = documentFront;
    orderDetail.documentBack = documentBack;
    orderDetail.identityType = identityType;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    
  }

  render() {
    const { cardList, documentBack, documentFront, maskList } = this.state;
    let nextBtn = false;
    if (cardList[2].checked === true) {
      nextBtn = documentFront !== "";
    } else {
      nextBtn = documentFront !== "" && documentBack !== "";
    }

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="info-page">
          <HeadBar {...this.props} />
          <div className="info-box">
            <h1>識別タイプを選択</h1>
            <p className="p">
              (証明書の種類を選択し、証明書の写真をアップロードする。
              証明書の写真をアップロード)
            </p>
            {cardList.map((item, index) => {
              return (
                <div
                  className="input-row"
                  key={index}
                  onClick={() => this.changeChecked(index)}
                >
                  <Radio checked={item.checked}></Radio>{" "}
                  {item.title === "Identity card" && "身分証明書"}{" "}
                  {item.title === "Driver's license" && "運転免許証"}
                  {item.title === "Passport" && "パスポート"}
                </div>
              );
            })}
            <div className="id-card">
              <div
                className="left"
                style={{
                  backgroundImage: `url(${BASE_URL + documentFront})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                }}
              >
                {documentFront === "" ? (
                  <img src={idCard} alt="" />
                ) : (
                  <div className="img-mask">
                    <div className="size-box">
                      {maskList.map((item, index) => {
                        return (
                          <li key={index}>
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                            <BWM
                              size={20}
                              className="bwm-icon"
                              color="rgba(0,0,0,0.6)"
                            />
                          </li>
                        );
                      })}
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  className="file-box"
                  onChange={(e) => this.upload(e, "front")}
                />
              </div>
              {cardList[2].checked ? (
                ""
              ) : (
                <div
                  className="right"
                  style={{
                    backgroundImage: `url(${BASE_URL + documentBack})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                  }}
                >
                  {documentBack === "" ? (
                    <img src={cardBack} alt="" />
                  ) : (
                    <div className="img-mask">
                      <div className="size-box">
                        {maskList.map((item, index) => {
                          return (
                            <li key={index}>
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                              <BWM
                                size={20}
                                className="bwm-icon"
                                color="rgba(0,0,0,0.6)"
                              />
                            </li>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <input
                    type="file"
                    className="file-box"
                    onChange={(e) => this.upload(e, "back")}
                  />
                </div>
              )}
            </div>
            {nextBtn ? (
              <div className="check" onClick={this.routeSelfie}>
                次のページ
              </div>
            ) : (
              <div className="btn">次のページ</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Info;
