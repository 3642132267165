import React from "react";

const Bmw = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="4141"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M516.48 0C236.501333 0 9.301333 229.056 9.301333 512S236.202667 1024 516.48 1024c280.298667 0 507.2-229.056 507.2-512S796.48 0 516.48 0z m474.090667 512c0 264.277333-212.288 478.592-474.090667 478.592C254.72 990.592 42.410667 776.277333 42.410667 512c0-264.277333 212.288-478.592 474.090666-478.592 261.76 0 474.069333 214.314667 474.069334 478.592z"
      fill={color}
      p-id="4142"
    ></path>
    <path
      d="M516.928 208.384v16.64c156.757333 0 284.266667 128.746667 284.266667 286.976h16.512c0-167.68-134.656-303.616-300.778667-303.616z m0 590.592c-156.736 0-284.266667-128.746667-284.266667-286.976h-16.490666c0 167.68 134.656 303.616 300.757333 303.616"
      fill={color}
      p-id="4143"
    ></path>
    <path
      d="M762.176 294.677333c6.869333 7.530667 16.704 19.562667 22.357333 26.794667l102.570667-65.92a630.741333 630.741333 0 0 0-19.370667-24.384l-65.002666 43.349333-4.48 3.904 3.285333-4.821333 28.629333-57.792-20.266666-20.458667-57.258667 28.885334-4.778667 3.328 3.882667-4.522667 42.944-65.621333c-7.466667-6.613333-14.933333-12.330667-24.170667-19.562667l-65.28 103.552c8.341333 6.314667 19.072 15.936 25.941334 22.272l61.717333-31.914667 3.861333-2.986666-2.986666 3.882666-31.573334 62.016zM529.301333 163.434667l28.032-63.509334 1.792-5.717333-0.597333 6.016 2.986667 84.906667c9.834667 0.896 19.968 2.389333 30.122666 3.904l-4.48-125.525334a658.837333 658.837333 0 0 0-42.026666-3.306666l-27.733334 69.226666-0.917333 5.12-0.874667-5.12-27.733333-69.226666c-14.016 0.597333-28.032 1.813333-42.048 3.306666l-4.48 125.525334c10.154667-1.813333 20.266667-3.008 30.122667-3.925334l2.986666-84.885333-0.597333-6.016 1.792 5.717333 28.032 63.509334h25.621333zM302.4 263.68c16.106667-17.152 25.344-37.333333 9.237333-56.298667-8.938667-10.24-23.552-12.629333-36.053333-7.509333l-1.216 0.597333 0.298667-1.216c1.792-4.8 2.986667-20.458667-10.133334-31.296a30.933333 30.933333 0 0 0-22.656-6.314666c-15.210667 1.493333-26.837333 12.032-58.730666 47.850666a970.730667 970.730667 0 0 0-32.213334 39.125334l87.68 83.989333c28.906667-32.512 40.832-44.842667 63.786667-68.928z m-114.496-20.778667c17.6-21.674667 36.373333-41.536 45.013333-49.365333 2.709333-2.410667 5.674667-4.8 9.258667-6.016 5.653333-1.493333 11.626667 2.410667 13.12 8.426667 1.493333 6.016-2.389333 11.733333-6.570667 16.256-9.237333 10.538667-43.52 46.656-43.52 46.656l-17.28-15.957334z m34.282667 33.109334l44.16-46.933334c4.16-4.522667 7.146667-7.253333 10.112-8.746666 3.882667-1.813333 8.064-2.410667 11.946666 0.298666a10.858667 10.858667 0 0 1 4.458667 11.136c-1.194667 5.12-5.077333 9.941333-8.64 13.546667-4.48 5.12-44.138667 47.573333-44.138667 47.850667l-17.877333-17.152z"
      fill={color}
      p-id="4144"
    ></path>
    <path
      d="M516.48 208.298667V512H215.637333c0-167.957333 134.464-303.701333 300.842667-303.701333zM817.045333 512c0 167.658667-134.485333 303.701333-300.842666 303.701333V512h300.842666z"
      fill={color}
      p-id="4145"
    ></path>
  </svg>
);

export default Bmw;


