import React from "react";

const DoubleAllowRight = ({
    viewBox = "0 0 24 24",
    color="#ffffff",
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="12946"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M151.467 204.373a34.133 34.133 0 1 1 48.213-48.213l331.947 331.947a34.133 34.133 0 0 1 0 48.213L199.68 867.84a34.133 34.133 0 1 1-48.213-48.213L459.093 512z m341.333 0a34.133 34.133 0 0 1 48.213-48.213L872.96 488.107a34.133 34.133 0 0 1 0 48.213L541.013 868.267a34.133 34.133 0 0 1-48.213-48.214L800.427 512z"
      fill={color}
      p-id="12947"
    ></path>
  </svg>
);

export default DoubleAllowRight;