import React, { Component } from "react";
import "../style/bmw.scss";
import firstImg from "../image/cannes1.jpg";
import video1 from "../image/video1.mp4";
import img2 from "../image/img4.jpg";
import video2 from "../image/video2.mp4";
import Bmw from "../icon/Bmw";
import DoubleAllowRight from "../icon/DoubleAllowRight";
import AllowLeft from "../icon/AllowLeft";
import video4 from "../image/video4.mp4";

class BMW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driveNum: "",
    };
    this.back = this.back.bind(this);
    this.routeModel = this.routeModel.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let driveNum = Math.floor(Math.random() * (50 - 200 + 1)) + 200;
    this.setState({ driveNum });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  routeModel() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Model",
        search: location.search,
      });
  }

  render() {
    const { driveNum } = this.state;
    return (
      <React.Fragment>
        <div className="bmw-page">
          <div className="mask">
            <AllowLeft size={20} className="allowLeft" onClick={this.back} />
            <div className="top-title">
              今日の残りの {driveNum} テストドライブ
              <p className="top-p">
                テスト後のフィードバックを楽しみにしている.
              </p>
            </div>
            <div className="logo">
              <Bmw size={90} />
            </div>
            <h1>選べる4種類の車</h1>
            <h2>1年間無料試乗</h2>
            <div className="btn" onClick={this.routeModel}>
              BMWを1年間無料で試乗できる
              <DoubleAllowRight size={22} className="double" color="#fff" />
            </div>
          </div>
          <div className="second-row">
            <video
              src={video1}
              autoPlay={true}
              loop={true}
              playsInline={true}
            ></video>
          </div>
          <div className="first-row">
            <img src={img2} className="first-img" />
          </div>
          <div className="first-row">
            <img src={firstImg} alt="" className="first-img" />
          </div>
          <div className="fourth-row">
            <video
              src={video4}
              autoPlay={true}
              loop={true}
              playsInline={true}
            ></video>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BMW;
