import React, { Component } from "react";
import "../style/document.scss";
import HeadBar from "./common/HeadBar";
import card from "../image/card2.gif";

class Document extends Component {
  constructor(props) {
    super(props);
    this.routeInfo = this.routeInfo.bind(this);
  }

  routeInfo() {
    let { location, history } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Info",
        search: location.search,
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="document-page">
          <HeadBar {...this.props} />
          <div className="document-box">
            <h1>検証してみよう</h1>
            <h2>以下の簡単な手順に従ってください</h2>
            <div className="gif-box">
              <img src={card} alt="" />
            </div>
            <div className="btn" onClick={this.routeInfo}>次のページ</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Document;
